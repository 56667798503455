import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");

export interface ICommunityObject {
  id: string,
  type: string,
  attributes: {
      id: number,
      name: string,
      description: string,
      body: string,
      account_id: number,
      created_at: string,
      updated_at: string,
      model_name: string,
      account: {
          id: string,
          type: string,
          attributes: {
              activated: boolean,
              email: string,
              type: string,
              created_at: string,
              updated_at: string,
              device_id: null,
              unique_auth_id: string,
              full_name: string,
              user_name: string,
              dob: null,
              country: null,
              platform: null,
              style: string,
              image: string
          }
      }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  toggle: boolean;
  isLoading: boolean;
  token: string;
  postFlatlistItemSelect: ICommunityObject[];
  refreshing: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCreatedPostApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      toggle: false,
      isLoading: false,
      token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODc2LCJleHAiOjE3MTIzMDg2MTUsInRva2VuX3R5cGUiOiJsb2dpbiJ9.ygJHyd7yOT7k-FBLUezoBTmEaCYnSzVFmXgYkdaMpKJH0oUF8B5kuXAzYQYXdP2rat4YV4XDs9LihTiqKwRDmg",
      refreshing: false,
      postFlatlistItemSelect: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getAllCreatedPostApiCallId) {

          if (!responseJson.errors) {
            this.setState({ isLoading: false, refreshing: false,
              postFlatlistItemSelect: responseJson.data })
           
          } else {
            this.setState({ isLoading: false, refreshing: false })
          }
         
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  gotocreatePost = {
    onPress: () =>
        this.props.navigation.replace('CreateNewPost')
  }

  gotoCreatePost = {
    onClick: () =>
        this.props.navigation.navigate('CreateNewPost')
  }

  getAllUpadatedPost_Api = async () => {
    this.setState({ isLoading: true, refreshing:true })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: (this.state.token)
    };

    this.setState({ isLoading: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    this.getAllCreatedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPostEndPoint    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };
  // Customizable Area End
 
  
}